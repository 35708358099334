//import ReactDOM from 'react-dom/client';
import {Routes,Route} from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { NavBar } from './components/NavBar/NavBar';
import { Footer } from './components/Footer/Footer';
import { Home } from './pages/Home/Home';
import { About } from './pages/About/About';
import { Event } from './pages/Event/Event';
import { Department } from './pages/Department/Department';
import { DepartmentDetails } from './pages/DepartmentDetails';
import { News } from './pages/News/News';
import { NewsDetails } from './pages/NewsDetails';
import { Test } from './pages/Test';
import { Resources } from './pages/Resources/Resources';
import { Podcast } from './pages/Podcast/Podcast';
import { Sermon } from './pages/Sermon/Sermon';
import { ContactUs } from './pages/ContactUs/ContactUs';
import { SignUpAam } from './pages/SignUpAam/SignUpAam';
import { FoodBank } from './pages/FoodBank/FoodBank';
import { Review } from './pages/Review/Review';
import { Donation } from './pages/Donation/Donation';
import { Give } from './pages/Give/Give';
import { LoginAam } from './pages/LoginAam/LoginAam';
import { LogoutAam } from './pages/LogoutAam/LogoutAam';
import { Category } from './pages/Category/Category';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { LiveStream } from './pages/LiveStream/LiveStream';
import { EventDetails } from './pages/EventDetails';
import { Volunteer } from './pages/Volunteer/Volunteer';
import { Kcile } from './pages/Kcile/Kcile';
import { KcileReg } from './pages/KcileReg/KcileReg';



//import logo from './logo.svg';
//import './App.css';

function App() {
  return ( 
    <div>
       <Router>
          <NavBar/>
          <Routes>
              <Route exact path = "/" element={<Home/>}></Route>
              <Route path = "/about" element={<About/>}></Route>
              <Route path = "/events" element={<Event/>}></Route>
              <Route path = "/departments" element={<Department/>}></Route>
              <Route path = "/dept-details" element={<DepartmentDetails/>}></Route>
              <Route path = "/news-details" element={<NewsDetails/>}></Route>
              <Route path = "/news" element={<News/>}></Route>
              <Route path = "/resources" element={<Resources/>}></Route>
              <Route path = "/podcasts" element={<Podcast/>}></Route>
              <Route path = "/sermons" element={<Sermon/>}></Route>
              <Route path = "/contactus" element={<ContactUs/>}></Route>
              <Route path = "/signupaam" element={<SignUpAam/>}></Route>
              <Route path = "/foodbank" element={<FoodBank/>}></Route>
              <Route path = "/review" element={<Review/>}></Route>
              <Route path = "/donation" element={<Donation/>}></Route> 
              <Route path = "/give" element={<Give/>}></Route>
              <Route path = "/loginaam" element={<LoginAam/>}></Route>
              <Route path = "/dashboard" element={<Dashboard/>}></Route>
              <Route path = "/logoutaam" element={<LogoutAam/>}></Route>
              <Route path = "/categories" element={<Category/>}></Route>
              <Route path = "/livestream" element={<LiveStream/>}></Route>
              <Route path = "/event-details" element={<EventDetails/>}></Route>
              <Route path = "/volunteers" element={<Volunteer/>}></Route>
              <Route path = "/kcile" element={<Kcile/>}></Route>
              <Route path = "/kcilereg" element={<KcileReg/>}></Route>
              <Route path = "/test" element={<Test/>}></Route>
          </Routes>
          <Footer/>
       </Router>
    </div>
  );
}

export default App;
